<!-- 校务 -- 校务信息管理 -- 假期管理 -- 列表 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        @onAdd="$router.push('./add')"
        has-add
        :current-page="page"
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      classOptions: '',  //年级配置
      schoolOptions: '',  //校区配置
      search: {},
      searchConfig: [
        {
          prop: "belong_school",
          placeholder: "请选择校区",
          tag: 'select',
          options: []
        },
        {
          prop: "belong_range",
          placeholder: "请选择年级",
          tag: 'select',
          label: 'name',
          value: 'id',
          options: []
        },
        {prop: "vacation_name", placeholder: "请输入关键字"},
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
      tableConfig: [
        {prop: "vacation_name", label: "假期名称"},
        {prop: "vacation_start_time", label: "假期开始时间"},
        {prop: "vacation_end_time", label: "假期结束时间"},
        {prop: "belong_user", label: "适用对象"},
        {prop: "belong_school", label: "校区"},
        {prop: "belong_range", label: "适用范围"},
        {
          prop: "handle", label: "操作", width: "180rem", handle: true,
          buttons: row => {
            return row.edit_status === 1 ? [{ type:"view", text:"详情" }] : [{ type:"edit", text:"编辑" },{ type:"delete", text:"删除" }]
          }
        },
      ],
    }
  },
  created() {
    this.$store.commit("setPage", 1);
    this.getData()
    this.getClass()
    this.getSchool()
  },
  activated() {
    this.getData()
  },
  computed: {
    ...mapState(["page"])
  },
  beforeRouteLeave(to, from, next) {
    // from.meta.keepAlive = true;
    next();
  },
  methods: {
    //获取年级配置
    getClass(){
      this.$_axios2('/api/common/screen/grade', {logic:1}).then(res => {
        this.searchConfig[1].options.push({name: '全部', id: 0})
        this.searchConfig[1].options = this.searchConfig[1].options.concat(res.data)
      })
    },

    //获取年级配置
    getSchool(){
      let obj = JSON.parse(sessionStorage.getItem('USER_INFO')).school_arr
      this.searchConfig[0].options.push({label: '全部', value: 0})
      Object.keys(obj).forEach(key => {
        this.searchConfig[0].options.push({label: obj[key], value: key})
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    getData() {
      let params = {...this.search, page: this.page};
      this.loading = true;
      this.$_axios2('/api/school-administration/vacation/list', {params,logic:1}).then(res => {
        let {data} = res
        this.tableData = data.list
        this.total = data.page.total
      }).finally(()=>this.loading = false);
    },

    //  点击编辑跳到编辑页面并把row这一行的数据作为参数传递。
    handleEdit(row, text, index) {
      if (text === '编辑') {
        this.$router.push('./edit?id=' + row.id)
      }

      if (text === '删除') {
        let msg = row.edit_status ? '是否确认删除？' : '删除后将影响课表信息和门禁系统，是否确认删除？'
        this.rowId = row.id
        this.$confirm(msg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$_axios2.post('/api/school-administration/vacation/del',{id: row.id},{logic:1}).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }).finally(() => {
            setTimeout(() => {
              this.getData()
            },1500)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }

      if (text === '详情') {
        this.$router.push('./details?id=' + row.id)
      }
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage", val);
      //拉取数据
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}

.button {
  // height: 100rem;
  //  padding-top: 30rem;
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  //background: rgba(24, 24, 120);
  opacity: 1;
  border-radius: 4rem;
  // display: flex;
  // .button-item {
  // width: 120rem;
  // font-size: 25rem;

  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // .additem{
  //   // font-size:20rem;
  //   display: flex;
  //   // justify-content: space-between;
  //   align-items: center;
  //   padding-left:3rem;
  //   //  word-spacing:10rem;
  //   .item{
  //     margin-left:10rem;
  //   }
  // }
}

// }
.table {
  margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}

.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}

.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-weight: normal;
  color: #3491fa;
}
</style>
